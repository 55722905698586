import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _24b46230 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _22b1a79a = () => interopDefault(import('../pages/career.vue' /* webpackChunkName: "pages/career" */))
const _35d3712c = () => interopDefault(import('../pages/career/_position.vue' /* webpackChunkName: "pages/career/_position" */))
const _1dea893b = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _755c3d6e = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _eceeb368 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _41c40f48 = () => interopDefault(import('../pages/selected-works/index.vue' /* webpackChunkName: "pages/selected-works/index" */))
const _6c5cf1d3 = () => interopDefault(import('../pages/services.vue' /* webpackChunkName: "pages/services" */))
const _0fabb856 = () => interopDefault(import('../pages/team/index.vue' /* webpackChunkName: "pages/team/index" */))
const _795ea766 = () => interopDefault(import('../pages/selected-works/_work.vue' /* webpackChunkName: "pages/selected-works/_work" */))
const _75ff087c = () => interopDefault(import('../pages/team/_member.vue' /* webpackChunkName: "pages/team/_member" */))
const _0d5da9ad = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _24b46230,
    name: "about"
  }, {
    path: "/career",
    component: _22b1a79a,
    name: "career",
    children: [{
      path: ":position?",
      component: _35d3712c,
      name: "career-position"
    }]
  }, {
    path: "/contact",
    component: _1dea893b,
    name: "contact"
  }, {
    path: "/newsletter",
    component: _755c3d6e,
    name: "newsletter"
  }, {
    path: "/privacy-policy",
    component: _eceeb368,
    name: "privacy-policy"
  }, {
    path: "/selected-works",
    component: _41c40f48,
    name: "selected-works"
  }, {
    path: "/services",
    component: _6c5cf1d3,
    name: "services"
  }, {
    path: "/team",
    component: _0fabb856,
    name: "team"
  }, {
    path: "/selected-works/:work?",
    component: _795ea766,
    name: "selected-works-work"
  }, {
    path: "/team/:member",
    component: _75ff087c,
    name: "team-member"
  }, {
    path: "/",
    component: _0d5da9ad,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
